<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div class="justify-start flex-grow w-full text-yellow-50 p-2 pb-1">
        <div class="text-2xl">Add Category</div>

        <div
          class="flex flex-col items-center justify-start flex-grow w-full text-yellow-50 p-3 pb-28"
        >
          <div class="md:py-8 lg:py-12 max-w-2xl w-full">
            <div class="flex flex-col bg-gray-900 bg-opacity-30">
              <div
                class="px-8 py-3 sm:py-6 rounded-b-xl border-r-2 border-b-2 border-l-2 border-t"
              >
                <div>
                  <div class="flex flex-col">
                    <div>
                      <div class="flex font-semibold mt-2">
                        <span
                          class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                        >
                          Station Name
                        </span>
                      </div>
                      <form class="mt-2">
                        <div
                          class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                        >
                          <input
                            v-model="station_name"
                            class="flex-grow w-full outline-none text-lg bg-transparent"
                          />
                        </div>
                      </form>
                    </div>
                  </div>

                  <div class="flex flex-col">
                    <div>
                      <div class="flex font-semibold mt-2">
                        <span
                          class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                        >
                          Address
                        </span>
                      </div>
                      <form class="mt-2">
                        <div
                          class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                        >
                          <input
                            v-model="address"
                            class="flex-grow w-full outline-none text-lg bg-transparent"
                          />
                        </div>
                      </form>
                    </div>
                  </div>

                  <div class="">
                    <div class="grid grid-cols-3 text-center">
                      <div class="flex flex-col">
                        <div
                          class="flex flex-col flex-1 justify-between font-semibold space-y-2 p-1 -m-1"
                        >
                          <div class="flex flex-col">
                            <div>
                              <div class="flex font-semibold mt-2">
                                <span
                                  class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                                >
                                  Lat
                                </span>
                              </div>
                              <form class="mt-2">
                                <div
                                  class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                                >
                                  <input
                                    v-model="lat"
                                    class="flex-grow w-full outline-none text-lg bg-transparent"
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col">
                        <div
                          class="flex flex-col flex-1 justify-between font-semibold space-y-2 p-1 -m-1"
                        >
                          <div class="flex flex-col">
                            <div>
                              <div class="flex font-semibold mt-2">
                                <span
                                  class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                                >
                                  Long
                                </span>
                              </div>
                              <form class="mt-2">
                                <div
                                  class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                                >
                                  <input
                                    v-model="lng"
                                    class="flex-grow w-full outline-none text-lg bg-transparent"
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col">
                        <div
                          class="flex flex-col flex-1 justify-between font-semibold space-y-2 p-1 -m-1"
                        >
                          <div class="flex flex-col">
                            <div>
                              <div class="flex font-semibold mt-2">
                                <span
                                  class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                                >
                                  Phone
                                </span>
                              </div>
                              <form class="mt-2">
                                <div
                                  class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                                >
                                  <input
                                    v-model="phone"
                                    class="flex-grow w-full outline-none text-lg bg-transparent"
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <span
                      class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                    >
                      Category
                    </span>

                    <div class="grid grid-cols-4 lg:grid-cols-6">
                      <div
                        class="md:flex md:items-center mb-6"
                        v-for="item in categorySelect"
                        :key="item.name"
                      >
                        <label class="block text-gray-500 font-bold">
                          <input
                            class="mr-2 leading-tight"
                            v-model="item.status"
                            type="checkbox"
                          />
                          <span class="text-sm">
                            {{ item.category_name }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="mt-4">
                    <span
                      class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                    >
                      หัวชาร์จ
                    </span>

                    <div class="grid grid-cols-2 lg:grid-cols-3">
                      <div
                        class="md:flex md:items-center mb-6"
                        v-for="item in Charging_head"
                        :key="item.name"
                      >
                        <label class="block text-gray-500 font-bold">
                          <input
                            class="mr-2 leading-tight"
                            v-model="item.status"
                            type="checkbox"
                          />
                          <span class="text-sm">
                            {{ item.name }}
                          </span>
                        </label>

                        <br />

                        <div
                          class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                          v-if="item.status"
                        >
                          <input
                            v-model="item.power"
                            placeholder="กำลังไฟ KW"
                            class="flex-grow w-full outline-none text-lg bg-transparent"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="grid grid-cols-2 lg:grid-cols-3 mt-4">
                      <div>
                        <span
                          class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                        >
                          Company
                        </span>
                        <br />

                        <div class="inline-block relative">
                          <select
                            class="block appearance-none w-full border border-gray-400 text-gray-600 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                            v-model="company"
                          >
                            <option value="PEA VOLTA">PEA VOLTA</option>
                            <option value="GO TO-U">GO TO-U</option>
                            <option value="PTT">PTT</option>
                            <option value="EAANYWHERE">EAANYWHERE</option>
                          </select>
                          <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                          >
                            <svg
                              class="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div>
                        <span
                          class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                        >
                          Payment
                        </span>

                        <br />

                        <div class="inline-block relative">
                          <select
                            class="block appearance-none w-full border border-gray-400 text-gray-600 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                            v-model="status_station"
                          >
                            <option value="HAVE A FEE">HAVE A FEE</option>
                            <option value="PUBLIC FREE">PUBLIC FREE</option>
                          </select>
                          <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                          >
                            <svg
                              class="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div>
                        <span
                          class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                        >
                          Time Open
                        </span>

                        <div class="inline-block relative">
                          <select
                            class="block appearance-none w-full border border-gray-400 text-gray-600 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                            v-model="time_out"
                          >
                            <option value="24 hours">24 hours</option>
                            <option value="Working hours 09:00:17:00">
                              Working hours 09:00:17:00
                            </option>

                            <option value="Working hours 09:00:18:00">
                              Working hours 09:00:18:00
                            </option>
                            <option value="Working hours 09:00:19:00">
                              Working hours 09:00:19:00
                            </option>
                            <option value="Working hours 09:00:20:00">
                              Working hours 09:00:20:00
                            </option>
                            <option value="Working hours 09:00:21:00">
                              Working hours 09:00:21:00
                            </option>
                            <option value="Working hours 09:00:22:00">
                              Working hours 09:00:22:00
                            </option>

                            <option value="Working hours 10:00:17:00">
                              Working hours 10:00:22:00
                            </option>
                            <option value="Working hours 10:00:18:00">
                              Working hours 10:00:18:00
                            </option>
                            <option value="Working hours 10:00:19:00">
                              Working hours 10:00:19:00
                            </option>
                            <option value="Working hours 10:00:20:00">
                              Working hours 10:00:20:00
                            </option>
                            <option value="Working hours 10:00:21:00">
                              Working hours 10:00:22:00
                            </option>
                            <option value="Working hours 10:00:22:00">
                              Working hours 10:00:21:00
                            </option>
                            <option value="Working hours 10:00:22:00">
                              Working hours 10:00:22:00
                            </option>
                          </select>
                          <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                          >
                            <svg
                              class="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-5">
                    <button
                      @click="addStatio_toList()"
                      class="flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-green-700 to-yellow-500"
                    >
                      ADD
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
          <div
            v-for="t in station.station_data"
            v-bind:key="t._id"
            class="w-full bg-gray-500 bg-opacity-10 my-1 hover:bg-gray-500 hover:bg-opacity-25 p-2 rounded-md"
          >
            <div class="flex">
              <div class="flex-grow flex flex-col sm:flex-row">
                <span
                  class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-200 to-green-400"
                >
                  {{ t.station_name }}
                </span>
                <!-- <span class="md:block mr-2 text-gray-500 text-sm">{{
                 
                }}</span> -->

                <div class="text-sm sm:text-base mr-4">
                  {{ t.remark }},
                  <span class="text-xs"> Open: {{ t.time_out }}, </span>
                </div>

                <span class="md:block mr-4 text-gray-500 text-sm"
                  >{{ t.status_station }},
                </span>

                <div class="text-sm sm:text-base mr-4">
                  {{ t.remark }},
                  <span class="text-xs"> Phone: {{ t.phone }}, </span>
                </div>

                <span class="md:block mr-4 text-gray-500 text-sm"
                  >{{ t.position }},
                </span>
              </div>
              <div
                class="flex-none text-right flex flex-col sm:flex-row text-sm sm:text-base"
              >
                <button
                  @click="deleteCategory(t)"
                  class="w-32 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-red-400 to-red-600"
                >
                  Delete
                </button>
              </div>
            </div>
            <div class="flex">
              <div class="flex-grow flex flex-col sm:flex-row">
                <span class="md:block mr-2 text-gray-500 text-sm">{{
                  t.category
                }}</span>
                <span
                  v-for="p in t.power"
                  :key="p"
                  class="md:block mr-2 text-gray-500 text-sm"
                  >{{ p.name }}</span
                >
              </div>
            </div>

            <span class="md:block mr-2 mt-3 text-gray-500 text-sm">{{
              t.address
            }}</span>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <footer>
          <StickFooterAdmin />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooterAdmin from "@/components/StickFooterAdmin.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import { mapGetters, mapActions } from "vuex";
import {
  POOL_ADDRESS,
  POOL_ADDRESS_ODD,
  VERSION,
  EXPLORER,
} from "../../../config";

import chameleon from "@/assets/svg/chameleon.vue";
import loader from "@/assets/svg/loader.vue";
import user from "@/api/user";
import func from "@/widgets/func";
import label from "@/widgets/label";
import moment from "moment";
import nativeToast from "native-toast";

import transactions from "../../public/transactions_bet.vue";

import Web3 from "web3";

import info from "@/api/info";

import blocks from "@/api/blocks";

import category from "@/api/category";

import map from "@/api/map";

export default {
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    ConnectWallet,
    StickFooterAdmin,
    FundSummary,
    loader,
    chameleon,
    loader,
    chameleon,
    transactions,
  },
  computed: mapGetters(["getCurrency", "getOnBlock", "getUser", "getBalance"]),

  data() {
    return {
      funds: [],
      ready: false,
      category: "-",
      categoryList: [],
      categorySelect: [],
      station: [],
      company: null,
      Charging_head: [
        { name: "AC Type 1", status: false, power: "" },
        { name: "AC Type 2", status: false, power: "" },
        { name: "AC GB/T", status: false, power: "" },
        { name: "DC CSS1 2", status: false, power: "" },
        { name: "DC CHAdeMO", status: false, power: "" },
        { name: "DC CSS2", status: false, power: "" },
        { name: "DC GB/T", status: false, power: "" },
      ],
    };
  },
  methods: {
    async getStations() {
      map.station().then((res) => {
        this.station = res.data;
      });
    },

    addCategory() {
      category.addCategory({ category_name: this.category }).then((res) => {
        this.getCategory();
      });
    },

    deleteCategory(i) {
      category.DeleteCategory({ category_id: i._id }).then((res) => {
        this.getCategory();
      });
    },

    async getCategory() {
      category.categoryList().then((res) => {
        this.categoryList = res.data;

        var a = res.data.category.map((c) => {
          c.select = false;
          return c;
        });

        this.categorySelect = a;
      });
    },

    async addStatio_toList() {
      var list_ca = [];

      this.categorySelect.map((m) => {
        if (m.status == true) {
          list_ca.push(m.category_name);
        }
      });

      var list_type = [];

      var power = [];

      this.Charging_head.map((m) => {
        if (m.status == true) {
          list_type.push(m.name);
          power.push({ name: m.name, power: m.power });
        }
      });

      await map
        .StationCraete({
          station_name: this.station_name,
          address: this.address,
          lat: this.lat,
          lng: this.lng,
          time_out: this.time_out,
          category: list_ca,
          status_station: this.status_station,
          phone: this.phone,
          website: this.website,
          type1: this.type1,
          type2: this.type2,
          company: this.company,
          typeChrage: list_type,
          power: power,
        })
        .then((res) => {
          this.getStations();

          this.getCategory();

          this.Charging_head = [
            { name: "AC Type 1", status: false, power: "" },
            { name: "AC Type 2", status: false, power: "" },
            { name: "AC GB/T", status: false, power: "" },
            { name: "DC CSS1 2", status: false, power: "" },
            { name: "DC CHAdeMO", status: false, power: "" },
            { name: "DC CSS2", status: false, power: "" },
            { name: "DC GB/T", status: false, power: "" },
          ];

          this.station_name = null;
          this.address = null;
          this.lat = null;
          this.lng = null;
          this.time_out = null;

          this.status_station = null;
          this.phone = null;
          this.website = null;
          this.type1 = null;
          this.type2 = null;
          this.company = null;

          this.addStation = false;
        });
    },
  },
  created() {
    this.getCategory();
    this.getStations();
  },
  updated() {},
};
</script>
<style scoped></style>
